import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiButton, { buttonClasses, ButtonProps } from "@mui/material/Button";

export const PrimaryButton = styled((props: ButtonProps) => (
    <MuiButton disableRipple disableElevation variant="contained" {...props} />
))(({ theme }) => ({
    color: `${theme.palette.common.white} !important`,
    textTransform: "none",
    fontSize: 14,
    border: "1px solid transparent",
    outline: "none",
    fontWeight: 400,
    lineHeight: "1.42857143em",
    padding: "4px 10px",
    height: "30px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [`&.${buttonClasses.containedPrimary}`]: {
        fontWeight: 600,
        backgroundColor: "var(--il-primary-button-background-color, #0052cc)",
    },
    [`&.${buttonClasses.containedPrimary}.Mui-disabled`]: {
        backgroundColor: theme.palette.action.disabledBackground,
    },
    [`&.${buttonClasses.containedPrimary}:hover`]: {
        backgroundColor: "var(--il-primary-button-background-color-hover, #0065ff)",
    },
}));