import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import LoginOverlay from "./shared/LoginOverlay";
import NotificationCenter from "./shared/NotificationCenter";
import PageLoader from "./shared/PageLoader";
import { Store } from "./store";

interface Props {
    store: Store;
    children: React.ReactNode;
}

const AppWrapper: React.FC<Props> = observer(function AppWrapper({ store, children }) {
    const { root } = store;
    const { muiTheme, isLoading, isUserAuthenticated } = root;

    return (
            <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <NotificationCenter store={root.notificationCenterStore} />
        {isLoading && !isUserAuthenticated ? (
            <PageLoader />
        ) : !isUserAuthenticated ? (
            <LoginOverlay store={store} />
        ) : (
            children
        )}
        </ThemeProvider>
        </StyledEngineProvider>
    );
});

export default AppWrapper;