import { makeAutoObservable } from "mobx";
import Root from "./root";
import Pages from "./pages";

interface MusicWishItem {
    songTitle: string;
}

export default class MusicWishes {

    public root: Root;
    public pageStore: Pages;
    public wishes: MusicWishItem[] | null;

    public forPageId: string;

    constructor(root: Root, pageStore: Pages) {

        this.wishes = null;
        this.forPageId = "";

        makeAutoObservable(this);
        this.root = root;
        this.pageStore = pageStore;
    }

    public async loadWishes() {

        if(!this.pageStore.selectedPage) {
            return;
        }

        if(this.forPageId !== "" && this.pageStore.selectedPage._id !== this.forPageId) {
            // reset
            this.wishes = null;
        }

        if(!this.wishes) {
            this.wishes = [];
            this.forPageId = this.pageStore.selectedPage._id;
            const fetched = await this.root.call("get",
                "/api/music-wishes?page=" + this.forPageId, undefined);
            this.setWishes(fetched || []);
        }
    }

    public getWishes() {
        return this.wishes || [];
    }

    public setWishes(wishes: any) {
        this.wishes = wishes;
    }
}