import { AlertColor } from "@mui/material/Alert";
import { makeAutoObservable, when } from "mobx";
import * as mobxUtils from "mobx-utils";
import { Notification } from ".";
import Root from "../../store/root";

export class NotificationCenterStore {
    public root: Root;
    public initialized: boolean;
    public notifications: Map<string, Notification>;

    constructor(root: Root) {
        this.root = root;
        this.initialized = true;
        this.notifications = new Map();

        makeAutoObservable(this);
    }

    get translations() {
        return this.root.translations;
    }

    get notificationsArray() {
        return Array.from(this.notifications.values());
    }

    get notification() {
        if (!this.initialized || this.notificationsArray.length === 0) {
            return {
                key: "",
                message: "",
                severity: "info" as AlertColor,
                isOpened: false,
            };
        }
        return this.notificationsArray[this.notificationsArray.length - 1];
    }

    public setInitialized(initialized: boolean = false) {
        this.initialized = initialized;
    }

    public navigationMoves(fn: () => void | Promise<any>, timeout: number = 400) {
        const start = Date.now();
        when(() => mobxUtils.now(100) - start >= timeout, fn, { name: "navigationMoves" });
    }

    public addNotification(message: string, severity: AlertColor) {
        if (severity !== "error" && severity !== "info" && severity !== "warning" && severity !== "success") {
            return;
        }
        if (this.notificationsArray.some((n) => n.message === message && n.severity === severity)) {
            return;
        }
        const time = new Date().getTime();
        const key = `${time + Math.random()}`;
        this.notifications.set(key, { key, message, severity, isOpened: true });
    }

    public removeNotification(key: string) {
        const notification = this.notifications.get(key);
        if (notification) {
            notification.isOpened = false;
        }
        this.navigationMoves(() => {
            this.notifications.delete(key);
        });
    }

    public successNotification(message: string) {
        this.addNotification(message, "success");
    }

    public infoNotification(message: string) {
        this.addNotification(message, "info");
    }

    public warningNotification(message: string) {
        this.addNotification(message, "warning");
    }

    public errorNotification(message: string) {
        this.addNotification(message, "error");
    }

    public errorNotificationServerProblem() {
        this.addNotification(this.translations.generic_error_message, "error");
    }

    public warningNotificationSessionExpired() {
        this.addNotification(this.translations.session_expired_message, "warning");
    }

    public warningNotificationInternetProblem() {
        this.addNotification(this.translations.internet_problem_message, "warning");
    }
}