import Alert, { AlertColor } from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Portal from "@mui/material/Portal";
import Snackbar from "@mui/material/Snackbar";
import { ThemeProvider } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import TruncateText from "../TruncateText";
import { NotificationCenterStore } from "./store";

export interface Notification {
    key: string;
    message: string;
    severity: AlertColor;
    isOpened: boolean;
}

interface Props {
    store: NotificationCenterStore;
}

const NotificationCenter: React.FC<Props> = observer(function NotificationCenter({ store }) {
    const { translations, notification, initialized, root } = store;
    if (!initialized) {
        return null;
    }

    const handleDismiss = () => {
        store.removeNotification(store.notification.key);
    };

    const actions: React.ReactNode[] = [];

    if (notification.severity === "error") {
        actions.push(
            <Button key="email" color="inherit" size="small" href="mailto:support@teamhellfall.com">
            {translations.support}
            </Button>,
    );
        actions.push(
            <Button key="close" onClick={handleDismiss} color="inherit" size="small">
            {translations.close}
            </Button>,
    );
    } else {
        actions.push(
            <Button key="close" onClick={handleDismiss} color="inherit" size="small">
            {translations.close}
            </Button>,
    );
    }

    return (
        <ThemeProvider theme={root.muiTheme}>
        <Portal container={document.body}>
        <Snackbar
            open={notification.isOpened}
    autoHideDuration={6000}
    sx={{ zIndex: 8500 }}
    onClose={handleDismiss}
    >
    <Alert variant="filled" elevation={8} severity={notification.severity}>
    <TruncateText
        text={notification.message}
    moreLabel={translations.more}
    lessLabel={translations.less}
    collapsedHeight={21}
    variant="body2"
    actions={actions}
    />
    </Alert>
    </Snackbar>
    </Portal>
    </ThemeProvider>
);
});

export default NotificationCenter;