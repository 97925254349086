import { styled } from "@mui/material/styles";

const Footer = styled((props) => (
    <footer {...props}>
        <section>
            <ul>
                <li>Copyright © {new Date().getFullYear()} Team Hellfall</li>
                <li>
                    <a href="https://teamhellfall.com/contact/" target="_blank" rel="noreferrer">
                        Support
                    </a>
                </li>
            </ul>
        </section>
    </footer>
))({
    "clear": "both",
    "float": "left",
    "width": "100%",
    "background": "var(--il-body-background-color, #f4f5f7)",
    "border": "1px solid var(--il-border-color, #dfe1e6)",
    "borderLeftWidth": 0,
    "borderRightWidth": 0,
    "borderBottomWidth": 0,
    "& section a": {
        color: "#7a869a",
        textDecoration: "none",
    },
    "& section": {
        color: "#7a869a",
        fontSize: "12px",
        lineHeight: "1.66666667",
        margin: "20px 0",
        padding: "0 10px 21px",
        minHeight: 44,
        textAlign: "center",
    },
    "& section>p:first-of-type, & section>ul:first-of-type": {
        margin: 0,
    },
    "& section>ul": {
        display: "block",
        fontSize: 0,
        listStyle: "none",
        padding: 0,
    },
    "& section>ul>li": {
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "1.66666667",
        padding: 0,
        whiteSpace: "nowrap",
    },
    "& section>ul>li:after": {
        content: '"\\B7"',
        marginLeft: 10,
        speak: "none",
    },
    "& section>ul>li:last-child:after": {
        display: "none",
    },
    "& section>ul>li+li": {
        marginLeft: 10,
    },
});

export default Footer;