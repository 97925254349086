import Card from "@mui/material/Card";
import MuiCardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Store } from "../../../store";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import {createSvgIcon} from "@mui/material";

interface Props {
    store: Store;
}

const PlusIcon = createSvgIcon(
    // credit: plus icon from https://heroicons.com/
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>,
    'Plus',
);

const ContentWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    clear: "both",
    transition: theme.transitions.create(["height", "margin", "width"]),
    maxWidth: `calc(100% - ${theme.spacing(2)})`,
    margin: `${theme.spacing(1)} auto`,
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
        margin: `${theme.spacing(4)} auto`,
        marginTop: 0,
        maxWidth: theme.breakpoints.values.md,
    },
    minHeight: 320,
}));

const CardStyled = styled(Card)(({ theme }) => ({
    minHeight: 240,
    height: "auto",
    overflowY: "scroll",
    marginTop: theme.spacing(2),
    transition: theme.transitions.create("height"),
    minWidth: "calc(100vw - 40px)",
    [theme.breakpoints.up("md")]: {
        minWidth: 600,
    },
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
    "padding": theme.spacing(2),
    "&:last-child": {
        paddingBottom: theme.spacing(2),
    },
}));

const Wrapper = styled("div")({
    display: "flex",
    justifyContent: "center",
});

export const Content: React.FC<Props> = observer(function Content({ store }) {

    const {root, pages} = store;
    const {translations} = root;

   pages.loadPages();

    return (
        <Wrapper>
            <ContentWrapper>
                <CardStyled variant="outlined">
                    <CardContent>
                        { "Pages" }

                        <Divider />
                        {store.pages.getPages().map((item) => (
                            <ListItem key={item.name} disablePadding>
                                <ListItemButton sx={{ textAlign: "center" }} onClick={(e) => pages.setSelectedPage(item)}>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}

                        <Divider />
                        <IconButton onClick={(e) => pages.createNew() }>
                            <PlusIcon />
                        </IconButton>
                    </CardContent>
                </CardStyled>
            </ContentWrapper>
        </Wrapper>
    );
});

export default Content;