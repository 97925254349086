import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import logoImage from "../media/logo.png";
import { Store } from "../store";
import { PrimaryButton } from "./Buttons/PrimaryButton";
import TextField from "@mui/material/TextField";

interface Props {
    store: Store;
}

const LoginCard = styled(Card)({
    margin: "60px auto",
    width: "40%",
    minWidth: "300px",
    padding: 30,
    position: "relative",
    backgroundColor: "#ffff",
    border: "none",
    textAlign: "center",
}) as typeof Card;

const LoginButton = styled(PrimaryButton)({
    maxWidth: "360px",
}) as typeof PrimaryButton;

const LoginImgContainer = styled("div")({
    margin: "0 auto !important",
    maxWidth: 200,
});

const LoginImg = styled("img")({
    width: "100%",
    objectFit: "cover",
});

const TypographyStyled = styled(Typography)({
    margin: 5,
    textAlign: "center",
});

const TextFieldStyled = styled(TextField)({
    margin: 5,
    textAlign: "center",
});

const LoginOverlay: React.FC<Props> = function LoginOverlay({ store }) {
    const { root } = store;
    const { backendServerUrl, translations } = root;

    return (
        <LoginCard variant="outlined">
            <LoginImgContainer>
                <LoginImg src={logoImage} alt="Importer Logo" />
            </LoginImgContainer>
            <TypographyStyled>{translations.login_header_styled}</TypographyStyled>
            <div style={{marginBottom: "20px"}}>
                <TextFieldStyled id="login-tenant" label="Tenant" variant="standard" onChange={(e) => root.loginRequest.tenant = e.target.value} />
                <TextFieldStyled id="login-email" label="Email" variant="standard" onChange={(e) => root.loginRequest.email = e.target.value} />
                <TextFieldStyled id="login-password" label="Password" variant="standard" type="password" onChange={(e) => root.loginRequest.password = e.target.value} />
            </div>
            <LoginButton onClick={(e) => root.doLogin() } fullWidth>
                {translations.loginButtonText}
            </LoginButton>
        </LoginCard>
    );
};
export default LoginOverlay;