import { observer } from "mobx-react-lite";
import AppBar from "../../shared/AppBar";
import Footer from "../../shared/Footer";
import { Store } from "../../store";
import ContentList from "./Content/List";
import ContentDetail from "./Content/Detail";

interface Props {
    store: Store;
}

const HomePage: React.FC<Props> = observer(function HomePage({ store }) {

    const { root } = store;
    const { themeMainClassName, translations } = root;

    return (
        <div className={themeMainClassName}>
            <AppBar store={store} />
            {!store.pages.selectedPage ? <ContentList store={store} /> : <ContentDetail store={store} />}
            <Footer />
        </div>
    );
});

export default HomePage;