import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import { convertRemToPixels, noopFunction } from "../utils";
import LinkSmallButton from "./Buttons/LinkSmallButton";
import MarkdownLite from "./MarkdownLite";

type Color =
    | "primary.main"
    | "secondary.main"
    | "error.main"
    | "warning.main"
    | "info.main"
    | "success.main"
    | "text.primary"
    | "text.secondary"
    | "text.disabled";

interface Props {
    text: string | React.ReactNode;
    moreLabel: string;
    lessLabel: string;
    actions?: React.ReactNode[];
    collapsedHeight?: number;
    onTextHeightChanged?: () => void;
    variant?: Variant;
    color?: Color;
    maxHeight?: string | number;
    useLinkButtons?: boolean;
    useMarkdown?: boolean;
    alignActions?: "left" | "right";
}

const Root = styled("div")({
    display: "flex",
});

const Container = styled("div")({
    display: "inline-block",
    width: "100%",
});

const ActionsContainer = styled(CardActions)({
    padding: "0 8px !important",
    marginRight: -10,
    marginTop: 8,
    position: "relative",
}) as typeof CardActions;

const ActionLinksContainer = styled(CardActions)({
    padding: "0 12px !important",
    marginTop: 4,
    position: "relative",
}) as typeof CardActions;

const TextContainer = styled("div")({
    display: "flex",
    maxHeight: "auto",
    position: "relative",
    maxWidth: "100% !important",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    whiteSpace: "initial",
    overflow: "hidden",
});

const TextContainerScroll = styled("div")({
    position: "relative",
    maxWidth: "100% !important",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    whiteSpace: "initial",
});

const TextWrapper = styled(Typography)({
    maxWidth: "100% !important",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    whiteSpace: "break-spaces",
}) as typeof Typography;

const FlexGrow = styled("div")({
    flexGrow: 1,
});

const TextOverlay = styled("div")(({ theme }) => ({
    background: "linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))",
    position: "absolute",
    height: 24,
    top: -24,
    left: 0,
    right: 0,
    transition: theme.transitions.create("display"),
    userSelect: "none",
}));

const CollapsedText: React.FC<{
    text: string | React.ReactNode;
    showMore: boolean;
    variant: Variant;
    maxHeight?: string | number;
    color?: Color;
    useMarkdown?: boolean;
}> = ({ text, showMore, variant, maxHeight, color, useMarkdown }) => {
    const renderText = useMarkdown ? (
        <MarkdownLite variant={variant}>{text}</MarkdownLite>
    ) : (
        <TextWrapper variant={variant} component="div" color={color}>
        {text}
        </TextWrapper>
);

    return (
        <TextContainer sx={{ ...(maxHeight && { maxHeight }) }}>
    <TextContainerScroll
        sx={{
    ...(showMore && {
            overflowY: "auto",
            maxHeight: "100%",
        }),
    }}
>
    {renderText}
    </TextContainerScroll>
    </TextContainer>
);
};

const Actions: React.FC<Props & { store: TruncateTextStore }> = ({
                                                                     collapsedHeight = 40,
                                                                     lessLabel,
                                                                     moreLabel,
                                                                     actions,
                                                                     useLinkButtons,
                                                                     onTextHeightChanged = noopFunction,
                                                                     store,
                                                                     variant = "body1",
                                                                     alignActions = "right",
                                                                 }) => {
    const theme = useTheme();
    const handleChange = () => {
        store.setShowMore(!store.showMore);
        if (onTextHeightChanged) {
            onTextHeightChanged();
        }
    };

    if (!store.textHeight || store.textHeight <= collapsedHeight) {
        return actions ? (
            <ActionsContainer>
                {alignActions === "right" && <FlexGrow />}
        {actions}
        </ActionsContainer>
    ) : null;
    }

    const overlayHeight = convertRemToPixels(theme.typography[variant].fontSize) * 2;

    if (useLinkButtons) {
        return (
            <ActionLinksContainer>
                <TextOverlay
                    sx={{
            display: !store.showMore ? "block" : "none",
                height: overlayHeight,
                top: -overlayHeight,
        }}
        />
        {alignActions === "right" && <FlexGrow />}
        <LinkSmallButton onClick={handleChange} disableRipple>
        {store.showMore ? lessLabel : moreLabel}
        </LinkSmallButton>
        {actions}
        </ActionLinksContainer>
    );
    }

    return (
        <ActionsContainer>
            {alignActions === "right" && <FlexGrow />}
    <Button color="inherit" size="small" onClick={handleChange}>
        {store.showMore ? lessLabel : moreLabel}
        </Button>
    {actions}
    </ActionsContainer>
);
};

const CollapsedTextWithSize = ({
                                   onResize,
                                   ...others
                               }: { onResize?: (width?: number, height?: number) => void } & any) => {
    const onResizeCallback = React.useCallback((width?: number, height?: number) => {
        if (onResize) {
            onResize(width, height);
        }
    }, []);
    const { ref } = useResizeDetector({
        onResize: onResizeCallback,
    });
    return (
        <div ref={ref}>
            <CollapsedText {...others} />
    </div>
);
};

class TruncateTextStore {
    showMore: boolean = false;
    textHeight: number | null = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setShowMore = (showMore: boolean) => {
        this.showMore = showMore;
    };

    setTextHeight = (textHeight: number | null) => {
        this.textHeight = textHeight;
    };
}

const TruncateText: React.FC<Props> = observer(function TruncateText(props) {
    const { text, collapsedHeight = 40, variant = "body1", maxHeight, color, useMarkdown = false } = props;
    const [store] = React.useState<TruncateTextStore>(new TruncateTextStore());

    const onResize = (_width?: number, height?: number) => {
        store.setTextHeight(height || null);
    };

    // const onSize = (size: any) => {
    //     store.setTextHeight(size.height);
    // };

    return (
        <Root>
            <Container>
                <Collapse
        in={store.showMore}
    collapsedSize={store.textHeight ? Math.min(collapsedHeight, store.textHeight) : collapsedHeight}
    >
    <CollapsedTextWithSize
        text={text}
    // onSize={this.onSize}
    onResize={onResize}
    showMore={store.showMore}
    variant={variant}
    maxHeight={maxHeight}
    color={color}
    useMarkdown={useMarkdown}
    />
    </Collapse>
    <Actions {...props} store={store} />
    </Container>
    </Root>
);
});

export default TruncateText;