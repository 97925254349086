import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRemark } from "react-remark";

interface Props {
    markdown: string;
    variant?: Variant;
    color?: "text.primary" | "text.secondary" | "error" | "warnings";
    textAlign?: "start" | "end" | "center";
}

const Wrapper = styled(Typography)({
    // "whiteSpace": "pre",
    "& pre": {
        margin: 0,
        fontSize: "inherit",
        fontFamily: "inherit",
        lineHeight: "inherit",
    },
    "& p": {
        margin: 0,
        fontSize: "inherit",
        fontFamily: "inherit",
        lineHeight: "inherit",
    },
    "& a": {
        fontSize: "inherit",
        fontFamily: "inherit",
        lineHeight: "inherit",
    },
}) as typeof Typography;

const Link = styled("a")({
    "color": "var(--il-link-button-color, #0052cc)",
    "textDecoration": "none",
    "&:hover": {
        textDecoration: "underline",
    },
});

export const ReactRemark: React.FC<Props> = observer(function ReactRemark({
                                                                              markdown,
                                                                              variant = "body2",
                                                                              color = "text.primary",
                                                                              textAlign = "start",
                                                                          }) {
    const [reactContent, setMarkdownSource] = useRemark({
        rehypeReactOptions: {
            components: {
                a: ({ href, children }: any) => (
                    <Link href={href} target="_blank" rel="noopener noreferrer">
                        {children}
                    </Link>
                ),
            },
        },
    });

    React.useEffect(() => {
        if (markdown && typeof markdown === "string") {
            setMarkdownSource(markdown);
        }
    }, [markdown]);

    return (
        <Wrapper component="div" variant={variant} color={color} textAlign={textAlign}>
            {reactContent}
        </Wrapper>
    );
});

export default ReactRemark;