import { makeAutoObservable } from "mobx";
import Root from "./root";

interface PageItem {
    _id: string;
    name: string;
    domain?: string;
    password?: string;
}

export default class Pages {

    public root: Root;
    public pages: PageItem[] | null;
    public selectedPage: PageItem | null;

    constructor(root: Root) {

        this.pages = null;
        this.selectedPage = null;

        makeAutoObservable(this);
        this.root = root;
    }

    public init() {
        this.selectedPage = null;
    }

    public async loadPages() {

        if(!this.pages) {
            this.pages = [];
            const fetched = await this.root.call("get", "/api/pages", undefined);
            this.setPages(fetched || []);
        }
    }

    public getPages() {
        return this.pages || [];
    }

    public setPages(pages: any) {
        this.pages = pages;
    }

    public setSelectedPage(page: PageItem | null) {
        this.selectedPage = page;
    }

    public getPageUrl(includePassword = false): string {

        if(!this.selectedPage) {
            return "";
        }

        const {_id, domain, password} = this.selectedPage;
        if(!domain) {
            if(!password || !includePassword) {
                return this.root.frontendServerUrl + "/" + encodeURIComponent(_id);
            } else {
                return this.root.frontendServerUrl + "/" + encodeURIComponent(_id) + "?psw=" + encodeURIComponent(password);
            }
        } else {
            if(!password || !includePassword) {
                return "https://" + domain;
            } else {
                return "https://" + domain + "?psw=" + encodeURIComponent(password);
            }
        }
    }

    public async createNew() {

        await this.root.call("post", "/api/pages", {
            name: "New page"
        });

        this.setPages(null);
    }

    public async removeSelected() {

        if(this.selectedPage) {
            await this.root.call("delete", "/api/pages/" + this.selectedPage._id, undefined);
            this.setPages(null);
            this.setSelectedPage(null);
        }
    }
}