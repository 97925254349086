import { observer } from "mobx-react-lite";
import React from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import store from "./store/index";

import HomePage from "./pages/Home";
import PagesPage from "./pages/Pages";
import GuestsPage from "./pages/Guests";
import MusicWishesPage from "./pages/MusicWishes";

const App: React.FC = observer(function App() {
    return (
        <AppWrapper store={store}>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <HomePage store={store} />
                    </Route>
                    <Route exact path="/pages">
                        <PagesPage store={store} />
                    </Route>
                    <Route exact path="/guests">
                        <GuestsPage store={store} />
                    </Route>
                    <Route exact path="/music-wishes">
                        <MusicWishesPage store={store} />
                    </Route>
                </Switch>
            </Router>
        </AppWrapper>
    );
});

export default App;