import axios from "axios";
import { configure, makeAutoObservable } from "mobx";

import Root from "./root";
import Pages from "./pages";
import Guests from "./guests";
import MusicWishes from "./musicWishes";

configure({ enforceActions: "never" });
axios.defaults.withCredentials = true;

export class Store {
    public root: Root;
    public pages: Pages;
    public guests: Guests;
    public musicWishes: MusicWishes;

    constructor() {
        this.root = new Root();
        this.pages = new Pages(this.root);
        this.guests = new Guests(this.root, this.pages);
        this.musicWishes = new MusicWishes(this.root, this.pages);

        this.init();

        makeAutoObservable(this);
    }

    public init() {
        this.root.configureReactions();
        this.root.checkAuth();
    }
}

const store = new Store();
export default store;