import Card from "@mui/material/Card";
import MuiCardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Store } from "../../../store";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import QRCode from "react-qr-code";
import {ViewArray} from "@mui/icons-material";

interface Props {
    store: Store;
}

const ContentWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    clear: "both",
    transition: theme.transitions.create(["height", "margin", "width"]),
    maxWidth: `calc(100% - ${theme.spacing(2)})`,
    margin: `${theme.spacing(1)} auto`,
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
        margin: `${theme.spacing(4)} auto`,
        marginTop: 0,
        maxWidth: theme.breakpoints.values.md,
    },
    minHeight: 320,
}));

const CardStyled = styled(Card)(({ theme }) => ({
    minHeight: 240,
    height: "auto",
    overflowY: "scroll",
    marginTop: theme.spacing(2),
    transition: theme.transitions.create("height"),
    minWidth: "calc(100vw - 40px)",
    [theme.breakpoints.up("md")]: {
        minWidth: 600,
    },
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
    "padding": theme.spacing(2),
    "&:last-child": {
        paddingBottom: theme.spacing(2),
    },
}));

const Wrapper = styled("div")({
    display: "flex",
    justifyContent: "center",
});

export const Content: React.FC<Props> = observer(function Content({ store }) {

    const {pages} = store;

    if(!pages.selectedPage) {
        return <div>404</div>;
    }

    return (
        <Wrapper>
            <ContentWrapper>
                <CardStyled variant="outlined">
                    <CardContent>

                        {"Page:"} {pages.selectedPage.name}

                        <IconButton
                            style={{float: "right"}}
                            onClick={(e) => pages.removeSelected()}>
                            <DeleteIcon/>
                        </IconButton>
                        <Divider style={{marginBottom: "10px", marginTop: "4px"}}/>

                        {"Id:"} {pages.selectedPage._id}
                        <Divider style={{marginBottom: "10px", marginTop: "4px"}}/>

                        {"Url:"} {pages.getPageUrl(false)}
                        <Divider style={{marginBottom: "10px", marginTop: "4px"}}/>

                        <div style={{height: "auto", margin: "0 auto", maxWidth: 256, width: "100%"}}>
                            <QRCode
                                size={256}
                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                value={pages.getPageUrl(true)}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <Divider style={{marginBottom: "10px", marginTop: "4px"}}/>

                        <a href="/#/guests">
                            Guests
                        </a>
                        <span style={{paddingRight: "6px"}}></span>
                        <a href="/#/music-wishes">
                            Music wishlist
                        </a>

                    </CardContent>
                </CardStyled>
            </ContentWrapper>
        </Wrapper>
    );
});

export default Content;