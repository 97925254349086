import { observer } from "mobx-react-lite";
import AppBar from "../../shared/AppBar";
import Footer from "../../shared/Footer";
import { Store } from "../../store";
import Content from "./Content";

interface Props {
    store: Store;
}

const GuestsPage: React.FC<Props> = observer(function GuestsPage({ store }) {

    const { root } = store;
    const { themeMainClassName, translations } = root;

    return (
        <div className={themeMainClassName}>
            <AppBar store={store} />
            <Content store={store} />
            <Footer />
        </div>
    );
});

export default GuestsPage;