import { makeAutoObservable } from "mobx";
import Root from "./root";
import Pages from "./pages";

interface GuestItem {
    name: string;
    email: string;
    count: number | null;
}

export default class Guests {

    public root: Root;
    public pageStore: Pages;
    public guests: GuestItem[] | null;

    public forPageId: string;

    constructor(root: Root, pageStore: Pages) {

        this.guests = null;
        this.forPageId = "";

        makeAutoObservable(this);
        this.root = root;
        this.pageStore = pageStore;
    }

    public async loadGuests() {

        if(!this.pageStore.selectedPage) {
            return;
        }

        if(this.forPageId !== "" && this.pageStore.selectedPage._id !== this.forPageId) {
            // reset
            this.guests = null;
        }

        if(!this.guests) {
            this.guests = [];
            this.forPageId = this.pageStore.selectedPage._id;
            const fetched = await this.root.call("get",
        "/api/guests?page=" + this.forPageId, undefined);
            this.setGuests(fetched || []);
        }
    }

    public getGuests() {
        return this.guests || [];
    }

    public setGuests(guests: any) {
        this.guests = guests;
    }

    public getGuestCount() {

        let count = 0;
        for(const guest of this.getGuests()) {
            if(guest.count) {
                count = count + guest.count;
            } else {
                count = count + 1;
            }
        }

        return count;
    }
}