const translations = {
    login_header_styled: "Welcome @ feierhub!",
    header: "This is feierhub",
    loginButtonText: "Login",

    less: "less",
    more: "more",
    support: "Support",
    close: "close",

    error_while_fetching: "Could not load data, please try again",
    not_authorized: "You are not authorized, please login",
    generic_error_message: "An error occurred, please try again",
    session_expired_message: "Your session has expired, please login",
    internet_problem_message: "There seems to be an issue with your network, please try again",

    menu_home: "Home",
    menu_pages: "Pages",
    menu_guests: "Global guests",

    nav_text: "FHUB",
    toolbar_text: "Open menu"
};

export default translations;