import MuiButton, { ButtonProps, buttonClasses } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const LinkSmallButton = styled((props: ButtonProps) => (
    <MuiButton variant="text" color="primary" disableRipple {...props} />
))(({ theme }) => ({
    height: "20px !important",
    fontWeight: 500,
    fontSize: theme.typography.caption.fontSize,
    textTransform: "none",
    color: "var(--il-link-button-color, #0052cc)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "0 0 2px 0",
    minWidth: "auto",
    [`& .${buttonClasses.startIcon}>.SvgIcon-root:nth-of-type(1)`]: {
        fontSize: 10,
    },
    [`& .${buttonClasses.endIcon}>.SvgIcon-root:nth-of-type(1)`]: {
        fontSize: 9,
    },
    [`& .${buttonClasses.startIcon}`]: {
        marginLeft: 0,
    },
    [`& .${buttonClasses.endIcon}`]: {
        marginRight: 0,
    },
})) as typeof MuiButton;

export default LinkSmallButton;