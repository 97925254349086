import { inputBaseClasses } from "@mui/material/InputBase";
import { ThemeOptions } from "@mui/material/styles";

export const muiThemeProps: ThemeOptions = {
    palette: {
        primary: {
            main: "#043460",
        },
        secondary: {
            main: "#3880ff",
        },
        mode: "light",
        background: {
            paper: "#ffffff",
            default: "#ffffff",
        },
    },
    typography: {
        fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@global": {
                    body: {
                        backgroundColor: "#f4f5f7",
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    [`& .${inputBaseClasses.input}`]: {
                        padding: "3.5px 6px",
                        minHeight: 22,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
                text: {
                    textTransform: "none",
                },
                textPrimary: {
                    "padding": "6px 0px",
                    "&:hover": {
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    height: 28,
                    width: 28,
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    marginBottom: 24,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                sizeSmall: {
                    padding: "0 8px",
                },
                head: {
                    lineHeight: "inherit",
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#5E6C84",
                    paddingBottom: "4px !important",
                    paddingTop: "4px !important",
                    verticalAlign: "top",
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    // fix popover focus after aui global css styles are applied
                    "&:focus-visible:focus": {
                        outline: "none !important",
                        boxShadow:
                            "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px !important",
                    },
                },
            },
        },
    },
};

export const getBackendUrl = () => {
    try {
        const backendUrl = process.env.REACT_APP_BACKEND_SERVER_URL;
        if (
            backendUrl &&
            (window.location.host.includes("localhost") ||
                window.location.host.includes("127.0.0.1") ||
                window.location.host.includes(".githubpreview.dev"))
        ) {
            // local to dev/prod for ui developer
            return backendUrl;
        }
        return `${window.location.protocol}//api.feierhub.com`;
    } catch (e) {
        return `${window.location.protocol}//api.feierhub.com`;
    }
};

export const getGenericFrontendUrl = () => {
    return `${window.location.protocol}//pages.feierhub.com`;
};

export const noopFunction = () => {
    // empty
};

export const convertRemToPixels = (rem?: string | number) => {
    try {
        if (!rem) {
            return 16;
        }
        if (typeof rem === "number") {
            return rem;
        }
        if (rem.includes("px")) {
            return parseFloat(rem.replaceAll("px", ""));
        }
        const remFactor = parseFloat(rem.replaceAll("rem", ""));
        return remFactor * parseFloat(getComputedStyle(document.documentElement).fontSize);
    } catch (e) {
        return 16;
    }
};