import { createTheme } from "@mui/material/styles";
import axios from "axios";
import { createBrowserHistory, History, Location } from "history";
import { makeAutoObservable, when } from "mobx";
import * as mobxUtils from "mobx-utils";
import { NotificationCenterStore } from "../shared/NotificationCenter/store";
import translations from "../translations";
import { getBackendUrl, muiThemeProps, getGenericFrontendUrl } from "../utils";

interface AuthUserInfo {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
}

interface LoginRequest {
    tenant: string;
    email: string;
    password: string | null;
}

export default class Root {
    public theme: "light" | "dark";
    public currentLanguage: "en" | "de";
    public initialized: boolean;
    public history: History;
    public location: Location;
    public locationChangedReactionDelay: number;
    public notificationCenterStore: NotificationCenterStore;

    public isLoading: boolean;
    public isUserAuthenticated: boolean;
    public authUserInfo: AuthUserInfo | null;
    public loginRequest: LoginRequest;

    public userToken: string | null;

    constructor() {
        this.theme = "light";
        this.currentLanguage = "en";
        this.initialized = false;
        this.history = createBrowserHistory();
        this.location = this.history.location;
        this.locationChangedReactionDelay = 0;
        this.notificationCenterStore = new NotificationCenterStore(this);

        this.isLoading = true;
        this.isUserAuthenticated = false;
        this.authUserInfo = null;
        this.loginRequest = { tenant: "", email: "", password: ""};
        this.userToken = null;

        makeAutoObservable(this);
    }

    get translations() {
        if (this.currentLanguage === "de") {
            return translations.de;
        }
        return translations.en;
    }

    get muiTheme() {
        return createTheme(muiThemeProps);
    }

    get themeMainClassName() {
        return this.theme === "light" ? "main-theme light-theme" : "main-theme dark-theme";
    }

    get backendServerUrl() {
        return getBackendUrl();
    }

    get frontendServerUrl() {
        return getGenericFrontendUrl();
    }

    public setTheme(theme: "light" | "dark" = "light") {
        this.theme = theme;
    }

    public setIsLoading(isLoading: boolean = false) {
        this.isLoading = isLoading;
    }

    public setIsUserAuthenticated(isUserAuthenticated: boolean = false) {
        this.isUserAuthenticated = isUserAuthenticated;
    }

    public setAuthUserInfo(authUserInfo: AuthUserInfo | null) {
        this.authUserInfo = authUserInfo;
    }

    public configureReactions() {
        this.history.listen((update) => {
            if (this.location.pathname !== update.location.pathname || this.location.hash !== update.location.hash) {
                this.setLocation(update.location);
                this.setLocationChangedReactionDelay(Date.now());
            }
        });
        this.setLocationChangedReactionDelay(Date.now());
    }

    public setLocation(location: Location) {
        this.location = location;
    }

    public setLocationChangedReactionDelay(locationChangedReactionDelay: number = 0) {
        this.locationChangedReactionDelay = locationChangedReactionDelay;
    }

    public getCookieValue(name: string): string {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }

    public setCookieValue(c_name: string, value: string, exdays: number) {
        let exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        let c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value;
    }

    public getUserToken() {

        if(this.userToken) {
            return this.userToken;
        }

        const fromCookie = this.getCookieValue("fh__at");
        this.userToken = fromCookie;
        return this.userToken;
    }

    public setUserToken(token: string | null) {
        if(token) {
            this.setCookieValue("fh__at", token, 31);
        } else {
            this.setCookieValue("fh__at", "", -1);
        }
        this.userToken = token;
    }

    public async call(method: string, path: string, body: any) {

        try {
            this.setIsLoading(true);
            const response = await axios(
                {
                    method,
                    url: `${this.backendServerUrl}${path}`,
                    headers: {
                        "Authorization": "Bearer " + this.getUserToken()
                    },
                    data: body,
                    withCredentials: false
                });

            this.setIsLoading(false);
            if (response.status == 200) {
                return response.data;
            }

            this.handleError(response);
        } catch (error: any) {
            // TODO: render network error
            this.setIsLoading(false);
        }

        return null;
    }

    public async checkAuth() {

        try {
            this.setIsLoading(true);
            const response = await axios.get(`${this.backendServerUrl}/api/auth/userinfo`,
                {
                    headers: {
                        "Authorization": "Bearer " + this.getUserToken()
                    },
                    withCredentials: false
                });

            this.setIsLoading(false);
            if (response.status !== 200) {
                this.handleError(response);
                this.setIsUserAuthenticated(false);
                this.setAuthUserInfo(null);
                return;
            }

            this.setIsUserAuthenticated(true);
            this.setAuthUserInfo(response.data);
        } catch (error: any) {
            // TODO: render network error
            this.setIsUserAuthenticated(false);
            this.setAuthUserInfo(null);
            this.setIsLoading(false);
        }
    }

    public async doLogin() {

        try {
            this.setIsLoading(true);
            const response = await axios.post(`${this.backendServerUrl}/api/auth/login`, this.loginRequest, {
                withCredentials: false
            });

            this.setIsLoading(false);
            if (response.status !== 201) {
                this.handleError(response);
                this.setIsUserAuthenticated(false);
                if(this.loginRequest) {
                    this.loginRequest.password = null;
                }
                return;
            }

            const {token} = response.data;
            this.setUserToken(token);
            await this.checkAuth();

        } catch (error: any) {
            // TODO: render network error
            this.setIsUserAuthenticated(false);
            this.setAuthUserInfo(null);
            this.setIsLoading(false);
        }
    }

    public async doLogout() {
        this.setUserToken(null);
        document.location.reload();
    }

    public handleError(error: any, silent: boolean = false) {
        try {
            if (error && error.data && error.data.message && error.status !== 401) {
                if (!silent) {
                    if (error.status >= 500) {
                        this.notificationCenterStore.errorNotification(error.data.message);
                    } else {
                        this.notificationCenterStore.warningNotification(error.data.message);
                    }
                }
            } else {
                if (error && error.status) {
                    if (error.status === 401 || error.status === 403) {
                        if (!silent) {
                            this.notificationCenterStore.warningNotificationSessionExpired();
                        }
                        this.delayedAction(() => {
                            window.location.reload();
                        }, 5000);
                        return;
                    } else {
                        if (!silent) {
                            this.notificationCenterStore.errorNotificationServerProblem();
                        }
                    }
                } else if (error && error.message === "Network Error") {
                    if (!silent) {
                        this.notificationCenterStore.warningNotificationInternetProblem();
                    }
                    return;
                }
            }
            if (error && error.headers) {
                delete error.headers;
            }
            if (error && error.config) {
                if (error.config.headers) {
                    delete error.config.headers;
                }
                if (error.config.auth) {
                    delete error.config.auth;
                }
            }
            if (error && error.request) {
                delete error.request;
            }
            if (error && error.data && error.data.headers) {
                delete error.data.headers;
            }
            if (error && error.auth) {
                delete error.auth;
            }
            let errorMessage: string = "";
            if (typeof error === "string") {
                errorMessage = error;
            } else if (error && error.message) {
                errorMessage = error.message;
            } else {
                try {
                    errorMessage = JSON.stringify(error);
                } catch (_) {
                    //empty
                }
            }
            try {
                const e = new Error();
                if (e.stack) {
                    const match = /\((.*):(\d+):(\d+)\)$/.exec(e.stack.split("\n")[2]);
                    if (match) {
                        errorMessage = `${match[1]} ${match[2]}:${match[3]} ==> ${errorMessage}`;
                    }
                }
            } catch (_) {
                errorMessage = `anonymous ==> ${errorMessage}`;
            }
            console.warn(errorMessage);
        } catch (err) {
            console.error("Generic error handler problem", err);
        }
    }

    public delayedAction(fn: () => void, timeout: number = 250) {
        const start = Date.now();
        when(() => mobxUtils.now() - start >= timeout, fn, {
            name: "delayedAction",
        });
    }
}