import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import { faIR } from "@mui/material/locale";
import React from "react";

import "./app.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

let optCache = {
    key: "muiltr"
};
const cache = createCache(optCache);
const theme = createTheme({
    direction: "ltr",
    palette: {
        mode: "light",
    }
}, faIR);

ReactDOM.render(
    <React.StrictMode>
        <div dir={"ltr"}>
            <CacheProvider value={cache}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </CacheProvider>
        </div>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();