import { Variant } from "@mui/material/styles/createTypography";
import { observer } from "mobx-react-lite";
import * as React from "react";
import ReactRemark from "./ReactRemark";

interface Props {
    key?: React.Key;
    variant?: Variant;
    color?: "text.primary" | "text.secondary" | "error" | "warnings";
    textAlign?: "start" | "end" | "center";
}

export const MarkdownLite: React.FC<Props> = observer(function MarkdownLite({
                                                                                key,
                                                                                children: markdown,
                                                                                variant,
                                                                                color,
                                                                                textAlign,
                                                                            }) {
    if (!markdown || typeof markdown !== "string") {
        return null;
    }
    return <ReactRemark key={key} markdown={markdown} variant={variant} color={color} textAlign={textAlign} />;
});

export default MarkdownLite;