import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import React from "react";

const BoxContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
    marginTop: "10%",
});

const PageLoader: React.FC = function PageLoader() {
    return (
        <BoxContainer>
            <Box>
                <CircularProgress />
            </Box>
        </BoxContainer>
    );
};

export default PageLoader;